import moment from "moment"
import _ from "lodash"
import Decimal from "decimal.js"

export default [
  {
    title: "开票日期",
    dataIndex: "invoiceDates",
  },
  {
    title: "发票号码",
    dataIndex: "invoiceNo",
  },
  {
    title: "开票方",
    dataIndex: "sellerName",
  },
  {
    title: "办税员",
    search: false,
    dataIndex: "taxClerk",
  },
  {
    title: "所属税区",
    dataIndex: "taxRegistrationName",
  },
  {
    title: "销售方法人",
    dataIndex: "sellerLegal",
    render: (_: any) => {
      return _ ? _ : "-"
    },
  },
  {
    title: "买方",
    dataIndex: "buyerName",
  },
  {
    title: "开票名称",
    dataIndex: "itemFullName",
    render: (_: any) => {
      return _ ? _ : "-"
    },
  },

  {
    title: "办税员",
    dataIndex: "taxClerk",
  },
  {
    title: "数量",
    dataIndex: "itemQuantity",
  },
  {
    title: "单位",
    dataIndex: "itemUnit",
  },
  {
    title: "单价",
    dataIndex: "itemPrice",
    render: (_: any) => {
      return _ ? _.toLocaleString() : "-"
    },
  },
  {
    title: "不含税金额",
    dataIndex: "amountWithoutTax",
    render: (_: any) => {
      return _ ? _.toLocaleString() : "-"
    },
  },
  {
    title: "合计税额",
    dataIndex: "taxAmount",
    render: (_: any) => {
      return _ ? _.toLocaleString() : "-"
    },
  },

  {
    title: "价税合计",
    dataIndex: "amountWithTax",
    render: (_: any) => {
      return _ ? _.toLocaleString() : "-"
    },
  },
  {
    title: "订单ID",
    dataIndex: "orderNo",
  },
  {
    title: "合同编号",
    dataIndex: "contractNos",
    render: (_: any) => {
      return _ ? _ : "-"
    },
  },
  {
    title: "发票类型",
    dataIndex: "invoiceTypeName",
    // render: (t: string) => invoiceTypeNameMap[t] || ''
  },
  {
    title: "规格",
    dataIndex: "itemSpecification",
    render: (_: any) => {
      return _ ? _ : "-"
    },
  },

  {
    title: "税率",
    dataIndex: "taxRate",
    render: (_: any) => {
      return _ ? Number(new Decimal(_).mul(new Decimal("100"))) + "%" : "-"
    },
  },
  {
    title: "开票人",
    dataIndex: "invoiceIssuer",
    render: (_: any) => {
      return _ ? _ : "-"
    },
  },
  {
    title: "发票备注",
    dataIndex: "notes",
    render: (_: any) => {
      return _ ? _ : "-"
    },
  },
  {
    title: "开票状态",
    dataIndex: "invoiceStatusName",
    // render: (t: string) => invoiceStatusNameMap[t] || ''
  },
]
